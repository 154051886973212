<template>
    <header>
        <img v-cache="img" :alt="`${title} header`">
        <h1>{{ title }}</h1>
    </header>
</template>

<script>
    export default {
        name: "PageHeader",
        props: {
            img: {
                type: String,
                default: '/img/page-header.jpg'
            },
            title:{
                type: String
            }
        }
    }
</script>

<style lang="scss" scoped>

    header {
        position: relative;
        max-height: 35vh;
        img {
            object-fit: cover;
            width: 100%;
            height: 35vh;
        }

        h1 {
            position: absolute;
            font-size: 2rem;
            top: 55%;
            left: 5%;
            color: #0d0c0c;
        }

    }
</style>
