<template>
    <div class="player">
        <div class="player__body">
            <div class="player__thumbnail">
                <img v-cache="thumbnail" :alt="title">
            </div>
            <div class="player__content">
                <div class="player__details">
                    <p>{{ title }}</p>
                    <span>{{ artist }}</span>
                </div>
                <div class="player__controllers">
                    <a @click="backward">
                        <i class="fas fa-backward"></i>
                    </a>
                    <a :class="isPlay? 'd-true' : 'd-none'" @click="playPauseToggle">
                        <svg class="svg-inline--fa fa-pause-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="pause-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm96-280v160c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16zm-112 0v160c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16z"></path></svg>
                    </a>
                    <a :class="isPlay? 'd-none' : 'd-true'" @click="playPauseToggle">
                        <svg class="svg-inline--fa fa-play-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"></path></svg>
                    </a>
                    <a @click="forward">
                        <i class="fas fa-forward"></i>
                    </a>
                    <div class="download">
                        <a :href="audio" target="_blank" :download="`${title}.mp3`">
                            <i class="fas fa-download"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="player__controllers">
            <a @click="backward">
                <i class="fas fa-backward"></i>
            </a>
            <a :class="isPlay? 'd-true' : 'd-none'" @click="playPauseToggle">
                <svg class="svg-inline--fa fa-pause-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="pause-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm96-280v160c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16zm-112 0v160c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16z"></path></svg>
            </a>
            <a :class="isPlay? 'd-none' : 'd-true'" @click="playPauseToggle">
                <svg class="svg-inline--fa fa-play-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"></path></svg>
            </a>
            <a @click="forward">
                <i class="fas fa-forward"></i>
            </a>
            <div class="download">
                <a :href="audio" target="_blank" :download="`${title}.mp3`">
                    <i class="fas fa-download"></i>
                </a>
            </div>
        </div>
        <div class="player__progress">
            <div class="player__progress-wrapper">
<!--                <span>{{(completed * 1000) | moment('m:ss') }}</span>-->
                <input
                    type="range"
                    :value="position"
                    @change="e => seekTo(e)"
                    min="0"
                    max="100"
                >
<!--                <span>{{(incompleted * 1000) | moment('m:ss') }}</span>-->
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'AudioPlayer',
    props: {
      title: String,
      thumbnail: String,
      artist: String,
      audio: String,
    },
    data: () => ({
      isPlay: false,
      completed: '00:00',
      incompleted: '00:00',
      position: 0,
      instance: null
    }),
    methods: {
      backward () {
        if (this.isPlay) {
          this.instances.currentTime = this.instances.currentTime - 10
        }
      },
      playPauseToggle () {
        const that = this
        if (!this.instances) {
          const audioObj = new Audio(this.audio)
          this.instances = audioObj
          this.instances.addEventListener('timeupdate', (event) => {
            const duration = audioObj.duration
            const currentTime = audioObj.currentTime
            that.completed = currentTime
            that.incompleted = duration - currentTime
            that.position = (100 / duration) * currentTime
            if (that.position >= 100) that.isPlay = false
          })
        }
        if (this.isPlay) {
          this.$emit('onStop', this)
          this.instances.pause()
          this.isPlay = false
        } else {
          this.$emit('onPlay', this)
          this.instances.play()
          this.isPlay = true
        }
      },
      forward () {
        if (this.isPlay) {
          this.instances.currentTime = this.instances.currentTime + 10
        }
      },
      seekTo (e) {
        if (this.isPlay) {
          this.instances.pause()
          this.instances.currentTime = (e.target.value / 100) * this.instances.duration
          this.instances.play()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "~@/assets/scss/_variables";
    .d-none {
        display: none;
    }
    .d-true{
        display: unset;
    }
    .player {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0px;
        background-color: white;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        &__body {
            position: relative;
            display: flex;
            width: 100%;
        }
        &__thumbnail {
            position: relative;
            max-width: 120px;
            img {
                width: 100%;
                height: auto;
            }
        }
        &__content, &__details {
            display: flex;
            flex-direction: column;
            padding: 0px;
        }
        &__content {
            margin-left: 1rem;
            width: 100%;
            .download {
                display: flex;
                justify-content: end;
            }
            .player__controllers{
                display: none;
            }
        }
        &__details {
            p {
                margin: 0px;
                font-size: 1.5rem;
            }
            span {
                margin: 0px;
                font-size: 1rem;
                color: #7d7d7d;
            }
        }
        &__controllers {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            a {
                font-size: 1rem;
                margin-left: 1rem;
                margin-right: 1rem;
                color: #7d7d7d;
                background: transparent;
                border: none;
                text-decoration: none;
                cursor: pointer;
            }
            a:nth-child(2), a:nth-child(3){
                font-size: 2rem;
                margin-left: 1.2rem;
                margin-right: 1.2rem;
            }
        }
        &__progress {
            position: relative;
            width: 100%;
        }
        &__progress-wrapper{
            display: flex;
            width: 100%;
            position: relative;
            padding-right: .5rem;
            padding-left: .5rem;
            input {
                flex-basis: calc(100% - 7.2%);
                color: $primary;
                cursor: pointer;
            }
            span {
                margin-top: .2rem;
                font-size: .8rem;
            }
        }
    }
    @media (min-width: 768px) {
        .player{
            &__content {
                .player__controllers{
                    display: flex;
                }
            }
            &__controllers{
                display: none;
            }
        }
    }
</style>
