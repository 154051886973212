<template>
    <div>
        <page-header title="Downloads"/>
        <div class="downloads">
            <section
                v-for="(audio, index) in $store.state.audios.filter(au => !au.deleted_at)"
                :key="index"
            >
                <audio-player
                        :artist="audio.artist"
                        :thumbnail="audio.thumbnail"
                        :title="audio.title"
                        :audio="audio.audio"
                        @onPlay="onPlayHandler"
                ></audio-player>
            </section>
        </div>
    </div>
</template>

<script>
    import PageHeader from "../components/comm/PageHeader";
    import AudioPlayer from '../components/download/AudioPlayer'
    export default {
        name: "Downloads",
        components: { AudioPlayer, PageHeader},
      data: () => ({
        currentPlay: null
      }),
      methods: {
        onPlayHandler (event) {
          if (this.currentPlay) {
            this.currentPlay.playPauseToggle()
          }
          this.currentPlay = event
        }
      }
    }
</script>

<style lang="scss" scoped>
    @import "~@/assets/scss/_variables";

    .downloads {
        margin-top: 4rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    section {
        margin-top: 2rem;
    }
</style>
